<template>
    <div id="page-nilai-rujukan-lab">
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>Detail Data Nilai Rujukan Lab</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <b-form>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Parameter Pemeriksaan
                      </template>
                      <b-form-input
                        v-model="is_data.nama_sub_tindakan_lab"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Jenis Kelamin
                      </template>
                      <b-form-input
                        v-model="is_data.jenis_kelamin_lab"
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Dari Umur 
                      </template>
                      <div style="width: 100%;display: flex;">
                        <div style="width:33.33%;display: flex;">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Tahun</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.dari_umur_tahun"
                            type="number"
                            disabled
                          ></b-form-input>
                        </div>

                        <div style="width:33.33%;display: flex;margin:0 10px">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bulan</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.dari_umur_bulan"
                            type="number"
                            disabled
                          ></b-form-input>
                        </div>

                        <div style="width:33.33%;display: flex;">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.dari_umur_hari"
                            type="number"
                            disabled
                          ></b-form-input>
                        </div>
                      </div>
                    </b-form-group>

                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Sampai Umur 
                      </template>
                      <div style="width: 100%;display: flex;">
                        <div style="width:33.33%;display: flex;">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Tahun</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.sampai_umur_tahun"
                            type="number"
                            disabled
                          ></b-form-input>
                        </div>

                        <div style="width:33.33%;display: flex;margin:0 10px">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bulan</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.sampai_umur_bulan"
                            type="number"
                            disabled
                          ></b-form-input>
                        </div>

                        <div style="width:33.33%;display: flex;">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.sampai_umur_hari"
                            type="number"
                            disabled
                          ></b-form-input>
                        </div>
                      </div>
                    </b-form-group>

                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Tipe Nilai <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        :options="options_nilai"
                        v-model="is_data.tipe_nilai"
                        placeholder="-- Pilih Nilai --"
                        disabled
                      ></b-form-select>
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col>
                  <b-table
                      :items="sub_nilai_lab"
                      :fields="fields"
                      responsive
                      show-empty
                      small
                      hover
                  >
                  
                  </b-table>
                </b-col>
              </b-row>
              <!-- <b-row>
                  <b-col cols="12" sm="12" md="12" lg="12" offset-md="10" offset-sm="10" offset-lg="10" class="mt-3">
                    <b-button :disabled="!isValid || disabeled" variant="success" @click="simpanData()">Simpan Data</b-button>
                  </b-col>
              </b-row> -->
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "detail_nilai_rujukan",
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        list_sub_tindakan: [

        ],
        disabeled: false,
        submit: false,
        datanya: "",
        ms_nilai_lab_id: null,
        jenis_kelamin: [
          {value: 'male', text: 'Male'},
          {value: 'female', text: 'Female'},
          {value: 'all', text: 'All'},
        ],

        options_nilai: [
          {value: 'text', text: 'Text'},
          {value: 'angka', text: 'Angka'},
        ],

        options: [
          { item: 'normal', name: 'Normal' },
          { item: 'negatif', name: 'Negatif' },
          { item: 'positif', name: 'Positif' },
          { item: 'reactive', name: 'Reactive' },
          { item: 'non_reactive', name: 'Non Reactive' },
        ],
        is_data: {
          dari_umur_bulan: 0,
          dari_umur_hari: 0,
          dari_umur_tahun: 0,
          sampai_umur_bulan: 0,
          sampai_umur_hari: 0,
          sampai_umur_tahun: 0,
          jenis_kelamin_lab: null,
          tipe_nilai: null,
          sub_tindakan_lab_id: null,
          sub_tindakan_lab:null,
        },
        sub_nilai_lab: [],
        fields: [
          {
            key: "nilai",
            label: "Nilai",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "nilai_lab",
            label: "Hasil Nilai Lab",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
        ],
      };
    },
    computed: {
    //   formString() {
    //     return JSON.stringify(this.is_data, null, 4);
    //   },
    //   isValid() {
    //     return !this.$v.is_data.$invalid;
    //   },
    //   isDirty() {
    //     return this.$v.is_data.$anyDirty;
    //   },
    //   formStringSub() {
    //     return JSON.stringify(this.is_sub_data, null, 4);
    //   },
    //   isValidSub() {
    //     return !this.$v.is_sub_data.$invalid;
    //   },
    //   isDirtySub() {
    //     return this.$v.is_sub_data.$anyDirty;
    //   },
        id_nilai() {
        return this.$route.query ? this.$route.query.id : null;
        },
    },
    activated(){
      this.getDetail()
    },
    async mounted() {
      // Set the initial number of items
      this.getDetail()
    },
    methods: {
      async getDetail(){
        let vm = this
        let detail = await vm.$axios('/ms_nilai_lab/details_by_id/' + vm.id_nilai)
        console.log(detail, 'ini detail');
        vm.is_data = detail.data.data[0]
        if(detail.data.data[0].sub_nilai_lab.length){
            
          vm.sub_nilai_lab = detail.data.data[0].sub_nilai_lab
          for (let i = 0; i < vm.sub_nilai_lab.length; i++) {
            let x = vm.sub_nilai_lab[i];
            if(x.nilai_normal_text){
              x.nilai = x.nilai_normal_text
            }else {
              x.nilai = `${x.nilai_minimum} - ${x.nilai_maximum}`
            }
          }
        }else {
          vm.sub_nilai_lab = []
        }
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
        vm.getDetail()
      },
    },
  };
  </script>

<style scoped>
  .input-group-text{
    font-size: 12px;
  }

 
</style>
  